import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import underline13Mobile from 'stile-shared/static/underline-13-mobile.png';
import underline13 from 'stile-shared/static/underline-13.png';
import styled from 'styled-components';

import iconPage from 'stile-shared/assets/icons/icon_page.svg';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import { ExploreCurriculum } from 'stile-shared/src/components/2024/common/ExploreCurriculum';
import { Icon } from 'stile-shared/src/components/2024/common/Icon';
import { Grid } from 'stile-shared/src/components/2024/layout/Grid';
import { Section } from 'stile-shared/src/components/2024/layout/Section';
import { TopSection } from 'stile-shared/src/components/2024/layout/TopSection';

import { PageLayout } from 'templates/2024/PageLayout';

export { Head } from 'templates/2024/Head';

const StileXTopSection = styled<typeof TopSection>(TopSection)`
  padding-bottom: 83px;
  ul {
    padding-left: 40px !important;
  }
  @media (max-width: 768px) {
    padding-bottom: 60px;
    background: var(--stile-grey-1);
    ul {
      padding-left: 25px !important;
    }
  }

  .mobile.gatsby-image-wrapper {
    display: none !important;
  }

  .grid-layout {
    padding-top: 80px !important;
    > div {
      padding-top: 60px !important;
    }

    @media (max-width: 768px) {
      padding-top: 32px !important;

      > div {
        padding-top: 30px !important;
      }

      .desktop.gatsby-image-wrapper {
        display: none;
      }
      .mobile.gatsby-image-wrapper {
        display: block !important;
        width: 100%;
        img {
          object-fit: contain !important;
        }
      }
    }
  }
`;

const IntroSection = styled<typeof Section>(Section)`
  .grid-layout {
    margin-top: 0 !important;
  }
  .content {
    margin: 120px auto 140px auto;
    padding: 0 25px 0 25px;
    padding: 0 !important;
    h2 {
      margin: 0 !important;
      p {
        margin: 0 !important;
        padding: 0 !important;
        &:nth-child(2) {
          padding: 40px 0 !important;
        }
      }
    }
    @media (max-width: 768px) {
      margin: 60px 25px 60px 25px;
    }
  }
`;

const WhatIsStileXSection = styled<typeof Section>(Section)`
  margin-top: 0 !important;
  grid-column: span 12;

  .content {
    padding-top: 120px !important;
    @media (max-width: 768px) {
      padding-top: 60px !important;
    }
  }

  position: relative;
  /* height: 400vh; */

  h2 {
    @media (max-width: 768px) {
      margin-bottom: 25px !important;
    }
  }

  .--subheader {
    color: var(--stile-grey-8);
    grid-column: 4 / span 6;
    text-align: center;
    margin-bottom: 0px;
    p {
      margin: 16px auto;
    }

    @media (max-width: 768px) {
      grid-column: 1 / span 12;
      p {
        &:first-child {
          margin-top: 0;
          margin-bottom: 10px;
        }
        &:last-child {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
    }
  }

  .--icons-header {
    margin-top: 40px;
    grid-column: 1 / span 12;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    gap: 28px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
    }

    .--icons-card {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      text-decoration: none;
      cursor: pointer;

      .gatsby-image-wrapper {
        width: 38x;
        height: 38x;
        img {
          width: 38px;
          height: 38px;
        }
      }

      h6 {
        padding: 0;
        margin: 0;
      }
    }
  }

  .grid-layout {
    margin-top: 0 !important;
    grid-column: 1 / span 12;
  }

  > .grid-layout {
    align-items: center;
    margin-bottom: 150px;
    @media (max-width: 768px) {
      margin-bottom: 80px;
    }
  }
`;

const StileXLayout = styled<typeof PageLayout>(PageLayout)`
  .underline-13 {
    display: inline-block;
    position: relative;
    :after {
      content: '';
      display: block;
      position: absolute;
      bottom: -3px;
      width: 100%;
      height: 14px;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
    }
  }

  .underline-13 {
    :after {
      background-image: url('${underline13}');
      @media (max-width: 768px) {
        background-image: url('${underline13Mobile}');
        bottom: -3px;
        left: -5px;
        width: 110%;
        height: 10px;
      }
    }
  }

  .header-wrapper {
    @media (max-width: 768px) {
      background-color: var(--stile-grey-1);
    }
  }
`;

const Quote = styled<typeof Section>(Section)`
  .content {
    padding-top: 150px !important;
    @media (max-width: 768px) {
      padding-top: 80px !important;
    }
  }

  .grid-layout {
    margin-top: 0 !important;

    @media (max-width: 768px) {
      /* margin-top: 25px !important; */
    }

    h2 {
      margin-bottom: 140px !important;
      @media (max-width: 768px) {
        margin-bottom: 60px !important;
      }
      .quote-attribution {
        margin-top: 32px;
        @media (max-width: 768px) {
          margin-top: 30px;
        }
        font-size: 16px;
        font-family: 'Graphik';
        font-weight: 600;
        line-height: 24px;
        text-align: center;

        .quote-title {
          font-weight: 400;
        }
      }
    }
  }
`;

const PTAAcademySection = styled<typeof Section>(Section)`
  margin: 0;
  padding: 0 0 0 0;
  grid-column: span 12;
  position: relative;

  .content {
    padding-top: 120px !important;
    padding-bottom: 150px !important;
    @media (max-width: 768px) {
      padding-bottom: 60px !important;
    }
  }

  :last-child {
    padding-bottom: 150px;
    @media (max-width: 768px) {
      padding-bottom: 30px;
    }
  }

  h2 {
    margin-bottom: 80px !important;
    @media (max-width: 768px) {
      margin-bottom: 30px !important;
      text-align: left !important;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    h2 {
      margin-bottom: 30px;
    }
    .content {
      padding: 0;
    }
  }

  .button {
    img {
      margin-left: 8px;
    }
  }

  .subheader {
    grid-column: 3 / span 8;
    text-align: center;
    margin: 0;

    h4 {
      margin-bottom: 32px;
    }

    p {
      margin: 0;
    }

    @media (max-width: 768px) {
      grid-column: 1 / span 12;

      p {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .content {
    .grid-layout {
      grid-column: span 12;
      align-items: center;
      margin-top: 25px !important;
    }

    @media (max-width: 768px) {
      padding-top: 60px !important;
      .grid-layout {
        margin-top: 20px !important;
        margin-bottom: 0px !important;
      }
    }
  }

  .--content {
    grid-column: 1 / span 12;
    display: grid;
    grid-template-columns: subgrid;

    .--left {
      grid-column: 1 / span 5;
      @media (max-width: 768px) {
        grid-column: 1 / span 12;
      }
      h6 {
        margin: 0;
        @media (max-width: 768px) {
          margin: 30px 0 0 0;
        }
      }

      > div {
        margin: 20px 0 32px 0;
        @media (max-width: 768px) {
          margin: 20px 0 20px 0;
        }

        p {
          padding-bottom: 14px;
          margin: 0;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      a {
      }
    }

    .gatsby-image-wrapper {
      grid-column: 7 / span 6;
      grid-row: 1 / span 3;
      @media (max-width: 768px) {
        grid-column: 1 / span 12;
        grid-row: 1;
      }
    }
  }
`;

type EvidenceCardProps = React.HTMLAttributes<HTMLDivElement> & {
  title: string;
  copy: React.ReactElement | React.ReactNode | string;
  image: React.ReactNode;
};

const BehindStileXCard = function ({ title, copy, image, ...props }: EvidenceCardProps) {
  return (
    <div className={`--evidence-card`} {...props}>
      <div className="--image --left">{image}</div>
      <h4>{title}</h4>
      <div className="--content">{copy}</div>
    </div>
  );
};

const StyledBehindStileXCard = styled<typeof BehindStileXCard>(BehindStileXCard)`
  grid-column: 3 / span 8;
  display: grid;
  padding: 0 0 76px 0;
  grid-template-columns: 170px auto;
  grid-template-rows: min-content min-content;

  @media (max-width: 768px) {
    grid-column: 1 / span 12;
    padding: 0 0 40px 0;
    /* flex-direction: row-reverse; */
    grid-template-columns: 52px auto;
    grid-template-rows: min-content min-content;
  }

  h4 {
    padding: 0 0 20px 25px;
    margin: 0;
    height: min-content;
    grid-column: 2 / span 1;

    @media (max-width: 768px) {
      height: 52px;
      align-content: center;
      grid-column: 2 / span 1;
      padding: 0 0 0 12px;
    }
  }

  .--content {
    grid-column: 2 / span 1;
    height: min-content;
    padding: 0 0 0 25px;

    p {
      padding: 0;
      margin: 0 0 25px 0;
      @media (max-width: 768px) {
        margin: 0 0 20px 0;
      }
    }

    @media (max-width: 768px) {
      grid-column: 1 / span 2;
      grid-row: 2;
      padding: 20px 0 0 0;
    }
  }

  .--image {
    width: auto;
    height: auto;
    .gatsby-image-wrapper {
      img {
        height: auto !important;
      }
    }
    grid-row: 1 / span 2;
    @media (max-width: 768px) {
      height: 52px;
      width: 52px;
      align-content: center;
      grid-column: 1 / span 1;
    }
    align-content: center;

    &.--left {
      display: flex;
      grid-column: 1 / span 1;
      @media (max-width: 768px) {
        grid-column: 1 / span 1;
        display: flex;
      }
    }
  }
`;

const ScienceBehindStileXSection = styled<typeof Section>(Section)`
  .content {
    padding-top: 120px !important;
    padding-bottom: calc(150px - 76px);
  }

  @media (max-width: 768px) {
    .content {
      padding-top: 60px !important;
      padding-bottom: 20px !important;
    }
  }

  .grid-layout {
    margin-top: 0 !important;
  }

  h2 {
    margin: 0 0 32px 0 !important;

    @media (max-width: 768px) {
      margin-bottom: 20px !important;
      text-align: left !important;
    }
  }

  .--subheader {
    color: var(--stile-grey-8);
    grid-column: 4 / span 6;
    text-align: center;
    margin-bottom: 100px;
    @media (max-width: 768px) {
      margin-bottom: 40px;
      grid-column: 1 / span 12;
      text-align: left;
      p {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const ClickToZoom = function (props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="--click-to-zoom" {...props}>
      <Icon className="--icon" iconId="zoom" iconSize="fontSize" />
      <div>Click to zoom</div>
    </div>
  );
};

const StyledClickToZoom = styled<typeof ClickToZoom>(ClickToZoom)`
  align-content: center;
  display: flex;
  @media (max-width: 768px) {
    display: none;
  }

  margin: 20px 0 0 0;
  padding: 0;

  color: var(--stile-grey-6);
  fill: var(--stile-grey-6);
  &:hover {
    color: var(--stile-green-3);
    fill: var(--stile-green-3);
  }

  .--icon {
    width: 15px;
    height: 16px;
    align-content: baseline;
    svg {
      height: 14px;
      width: 14px;
    }
  }

  div {
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 145.455% */
    font-family: 'Graphik';
  }
`;

type WhatsInStileXCardProps = React.HTMLAttributes<HTMLDivElement> & {
  id: string;
  title: string;
  content: string;
  image: React.ReactNode;
};

const WhatsInStileXCard = function ({
  id,
  title,
  content,
  image,
  ...props
}: WhatsInStileXCardProps) {
  const refDialogue = React.useRef<HTMLDialogElement | null>(null);

  return (
    <div id={id} className={`--whats-in-stile-x-card`} {...props}>
      <div className="--content">
        <h4>{title}</h4>
        <div>{content}</div>
      </div>
      <button
        className="--image"
        onClick={() => {
          refDialogue?.current?.showModal();
          refDialogue?.current?.addEventListener('click', function () {
            refDialogue?.current?.close();
          });
        }}
      >
        {image}
        <StyledClickToZoom />
      </button>
      <dialog ref={refDialogue}>{image}</dialog>
    </div>
  );
};

const StyledWhatsInStileXCard = styled<typeof WhatsInStileXCard>(WhatsInStileXCard)`
  grid-column: 1 / span 12;
  display: grid;
  grid-template-columns: subgrid;

  margin-bottom: calc(150px - 36px);
  margin-top: 0;

  &:first-child {
    margin-top: 110px;
    @media (max-width: 768px) {
      margin-top: 60px;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 60px;
  }

  .--content {
    grid-column: 1 / span 4;
    @media (max-width: 768px) {
      grid-column: 1 / span 12;
      grid-row: 2;
      margin-top: 30px;
    }

    align-content: center;

    h4 {
      margin: 0 0 32px 0;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
  }

  .--image {
    grid-column: 5 / span 8;

    @media (max-width: 768px) {
      grid-column: 1 / span 12;
      grid-row: 1;
    }

    border-radius: 32px;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;

    .gatsby-image-wrapper {
      border-radius: 32px;
      box-shadow: 0px 41.958px 25.37px 0px rgba(0, 0, 0, 0.04),
        0px 18.54px 18.54px 0px rgba(0, 0, 0, 0.06), 0px 4.879px 10.733px 0px rgba(0, 0, 0, 0.07);
      @media (max-width: 768px) {
        border-radius: 20px;
      }
    }
  }

  dialog {
    background-color: var(--stile-grey-8);
    opacity: 0.9;
    padding: 0;
    width: 90vw;
    height: 90vh;
    ::backdrop {
      background-color: var(--stile-grey-8);
      opacity: 0.9;
    }

    .gatsby-image-wrapper {
      object-fit: contain;
      position: unset;
      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }
`;

function TwentyTwentyFourStileXPage(props: PageProps) {
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const underline = entry.target;

          if (entry.isIntersecting) {
            setTimeout(() => {
              underline.classList.remove('underline-hidden');
              underline.classList.add('underline-animation');
            }, 500);
            return; // if we added the class, exit the function
          }

          // We're not intersecting, so remove the class!
          underline.classList.add('underline-hidden');
          underline.classList.remove('underline-animation');
        });
      },
      {
        threshold: 0.9,
      }
    );

    const numberOfUnderlines = 11;
    for (let i = 1; i <= numberOfUnderlines; i++) {
      const underline = document.querySelector(`.underline-${i}`);
      // console.log('underline', underline);
      if (underline) observer.observe(underline);
    }
  }, []);

  const exploreCurriculumImage = (
    <StaticImage
      src="../../components/our-principles/images/technology_explore_curriculum.png"
      alt="Teacher Saying Quality"
      placeholder="blurred"
      layout="constrained"
      width={124}
      height={124}
    />
  );

  return (
    <StileXLayout {...props}>
      <StileXTopSection
        headingContent={
          <span>
            The wonders of
            <br />
            Stile X workbooks
          </span>
        }
        subHeadingContent={
          <ul>
            <li>Five evidence-based, interwoven learning strategies.</li>
            <li>Simultaneously scaffolds scientific and English literacy.</li>
            <li>Full colour and made from recyclable paper.</li>
          </ul>
        }
        coverImage={
          <>
            <StaticImage
              src="../../../../stile-shared/assets/images/stile-x/cover_stile_x.png"
              alt="cover image"
              className="desktop"
              placeholder="none"
              objectFit="scale-down"
            />
            <StaticImage
              src="../../../../stile-shared/assets/images/stile-x/cover_stile_x_mobile.png"
              alt="cover image"
              className="mobile"
              placeholder="none"
            />
          </>
        }
        underlineNumber={14}
      />
      <IntroSection
        backgroundColor="--white"
        headingContent={
          <span>
            <p>With Stile{`’`}s workbooks students eXplore, they eXplain.</p>
            <p>Some call these workbooks our X-factor.</p>
            <p>
              We call them <span className="underline-13">Stile X.</span>
            </p>
          </span>
        }
      />
      <WhatIsStileXSection
        headingColor="--stile-green-3"
        headingContent={<span>What&apos;s in Stile X?</span>}
        backgroundColor="--stile-grey-1"
      >
        <div className="--subheader">
          <p>
            Stile X notebooks are used in class by students, either side-by-side with their laptop,
            or instead of it. They perfectly match the lessons in the digital curriculum and are
            specifically designed to make the most of pen and paper.
          </p>
          <p>Each Stile X workbook has four distinct sections:</p>
        </div>

        <div className="--icons-header">
          <a href="#whats-in-stile-x-card-worksheets" className="--icons-card">
            <StaticImage
              alt="Worksheets"
              src="../../../../stile-shared/assets/images/stile-x/icon-worksheets.png"
            />
            <h6 className="h7">Worksheets</h6>
          </a>
          <a href="#whats-in-stile-x-card-glossary" className="--icons-card">
            <StaticImage
              alt="Glossary"
              src="../../../../stile-shared/assets/images/stile-x/icon-glossary.png"
            />
            <h6 className="h7">Glossary</h6>
          </a>
          <a href="#whats-in-stile-x-card-practice-test" className="--icons-card">
            <StaticImage
              alt="Practice test"
              src="../../../../stile-shared/assets/images/stile-x/icon-practice-test.png"
            />
            <h6 className="h7">Practice test</h6>
          </a>
          <a href="#whats-in-stile-x-card-freeform-note-pages" className="--icons-card">
            <StaticImage
              alt="Freeform note pages"
              src="../../../../stile-shared/assets/images/stile-x/icon-freeform-note-pages.png"
            />
            <h6 className="h7">Freeform note pages</h6>
          </a>
        </div>

        <Grid>
          <StyledWhatsInStileXCard
            id="whats-in-stile-x-card-worksheets"
            title={'Worksheets'}
            content={
              'Beautiful, full-colour worksheets that support sense-making of scientific concepts with literacy exercises and creative activities.'
            }
            image={
              <StaticImage
                key={0}
                src="../../../../stile-shared/assets/images/stile-x/worksheet.png"
                alt="Self-paced training"
                objectFit="contain"
              />
            }
          />
          <StyledWhatsInStileXCard
            id="whats-in-stile-x-card-glossary"
            title={'Glossary'}
            content={
              'Learning science is like mastering a new language. Stile X glossaries boost vocabulary retention by encouraging students to formulate their own definitions for each term.'
            }
            image={
              <StaticImage
                key={0}
                src="../../../../stile-shared/assets/images/stile-x/glossary.png"
                alt="Self-paced training"
                objectFit="contain"
              />
            }
          />
          <StyledWhatsInStileXCard
            id="whats-in-stile-x-card-practice-test"
            title={'Practice test'}
            content={
              'New revision questions, along with detailed model answers, assist students in preparing for tests and gauging their understanding of learning goals.'
            }
            image={
              <StaticImage
                key={0}
                src="../../../../stile-shared/assets/images/stile-x/practice_test.png"
                alt="Self-paced training"
                objectFit="contain"
              />
            }
          />
          <StyledWhatsInStileXCard
            id="whats-in-stile-x-card-freeform-note-pages"
            title={'Freeform note pages'}
            content={
              'The blank pages at the back of the workbook provides space for students to engage in active note-taking, sketching and planning. Critically, it keeps all student work in one place.'
            }
            image={
              <StaticImage
                key={0}
                src="../../../../stile-shared/assets/images/stile-x/freeform_note_pages.png"
                alt="Self-paced training"
                objectFit="contain"
              />
            }
          />
        </Grid>
      </WhatIsStileXSection>

      <Quote
        backgroundColor="--stile-green-2"
        className=""
        headingContent={
          <div>
            “Stile is great by itself, but it’s even better with Stile X.”
            <div className="quote-attribution">
              Peter Eyre
              <div className="quote-title">Montmorency Secondary College</div>
            </div>
          </div>
        }
      >
        <></>
      </Quote>
      <ScienceBehindStileXSection
        backgroundColor="--white"
        // headingColor="--stile-green-3"
        headingContent="The science behind Stile X"
        textColor="--stile-grey-8"
      >
        <div className="--subheader">
          We’re science nerds, and are sticklers for evidence. Every Stile X workbook combines the
          following five evidence-based learning strategies to embed the core concepts of every
          lesson for every learner.
        </div>

        <StyledBehindStileXCard
          title="Retrieval practice"
          copy={
            <div>
              <p>
                Retrieval practice asks students to actively recall information frequently and in
                different forms to boost long-term retention and transfer of knowledge to new
                contexts.
              </p>
              <Button
                href="https://blog.stileeducation.com/the-science-of-stile-x-retrieval-practice/"
                newTab
                label="Read more"
                style="secondary"
              />
            </div>
          }
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/stile-x/science-1.png"
              alt="Retrieval practice"
            />
          }
        />
        <StyledBehindStileXCard
          title="Metacognition"
          copy={
            <div>
              <p>
                Metacognition supports students in monitoring their own learning by encouraging them
                to evaluate their level of comprehension, task performance, and the efficacy of
                their study strategies."
              </p>
              <Button
                href="https://blog.stileeducation.com/the-science-of-stile-x-metacognition/"
                newTab
                label="Read more"
                style="secondary"
              />
            </div>
          }
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/stile-x/science-2.png"
              alt="Metacognition"
            />
          }
        />
        <StyledBehindStileXCard
          title="Spaced repetition"
          copy={
            <div>
              <p>
                Spaced repetition involves revisiting a concept multiple times over a time period to
                facilitate better understanding and recall."
              </p>
              <Button
                href="https://blog.stileeducation.com/the-science-of-stile-x-spaced-repetition/"
                newTab
                label="Read more"
                style="secondary"
              />
            </div>
          }
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/stile-x/science-3.png"
              alt="Spaced repetition"
            />
          }
        />
        <StyledBehindStileXCard
          title="Elaboration"
          copy={
            <div>
              <p>
                Elaboration is all about adding details to knowledge, for instance connecting to
                prior knowledge or something personal, to help students deeply understand the
                concept and more easily remember it."
              </p>
              <Button
                href="https://blog.stileeducation.com/the-science-of-stile-x-elaboration/"
                newTab
                label="Read more"
                style="secondary"
              />
            </div>
          }
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/stile-x/science-4.png"
              alt="Elaboration"
            />
          }
        />
        <StyledBehindStileXCard
          title="Dual coding"
          copy={
            <div>
              <p>
                Dual coding involves presenting concepts in multiple ways, such as verbal, visual
                and textual, to give students multiple pathways to remember, understand and access
                that knowledge."
              </p>
              <Button
                href="https://blog.stileeducation.com/the-science-of-stile-x-dual-coding/"
                newTab
                label="Read more"
                style="secondary"
              />
            </div>
          }
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/stile-x/science-5.png"
              alt="Dual coding"
            />
          }
        />
      </ScienceBehindStileXSection>
      <PTAAcademySection
        backgroundColor="--stile-green-2"
        headingColor="--stile-grey-8"
        headingContent="How Stile X transformed learning at High Tech LA"
        subHeadingContent="Case Study"
        textColor="--stile-grey-8"
      >
        <div className="--content">
          <div className="--left">
            <h6>High Tech LA applied the scientific method to Stile X.</h6>
            <div>
              <p>
                The search for evidence: two classes, one with Stile X booklets packed with
                note-taking tools, practice tests, and study tips, and the other working without.
              </p>
              <p>
                As students dove into their studies, the numbers spoke loud and clear. Sixth graders
                using Stile X scored an impressive 4.5% higher (effect size: 0.8). Seventh graders
                saw gains too, with scores rising by 1.3% (effect size: 0.5).
              </p>
              <p>
                But it was about more than just numbers. Students felt more confident, explaining
                that Stile X helped them tackle tests and discover new ways of thinking. Teachers
                saw a shift too: students grew more autonomous, writing in full sentences and
                paragraphs without extra prompts. Stile X made learning feel accessible, relatable,
                and—dare we say it—more fun.
              </p>
              <p>Curious to dive deeper?</p>
            </div>
            <Button
              style="white"
              href="http://stileapp.com/go/StileXCaseStudy"
              newTab
              label={
                <>
                  <span>Read the full case study</span>
                  <Icon src={iconPage} iconSize="fontSize" />
                </>
              }
            />
          </div>
          <StaticImage
            alt="stile-x workbook"
            src="../../../../stile-shared/assets/images/stile-x/stile-x-workbook.png"
          />
        </div>
      </PTAAcademySection>
      <ExploreCurriculum
        title="Explore our core science curriculum"
        image={exploreCurriculumImage}
        exploreCardsData={[
          {
            title: 'Professional Learning',
            description:
              'Master Stile at your own pace with evidence-based pedagogies and personalised support.',
            url: '/what-is-stile/stile-pl/',
          },
          {
            title: 'Blog: Study skills for lifelong learning',
            description: 'The progression of study skills in Stile X from Year 7 to Year 10.',
            url: 'https://blog.stileeducation.com/stile-x-study-skills/',
          },
          {
            title: 'Evidence-based Pedagogy',
            description: 'The high-impact strategies built into every single Stile resource.',
            url: '/why-choose-stile/evidence-based-pedagogy/',
          },
        ]}
      />
    </StileXLayout>
  );
}

export default TwentyTwentyFourStileXPage;
