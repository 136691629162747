import React from 'react';

import styled from 'styled-components';

import { PageLayout } from 'templates/2024/PageLayout';

import FocusCards from '../../components/our-principles/FocusCards';
import HeroSection from '../../components/our-principles/HeroSection';
import PrinciplesSection from '../../components/our-principles/PrinciplesSection';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: -5;
`;

const OurPrinciples: React.FC = () => {
  return (
    <PageLayout>
      <Container>
        {/* This page is imported from the below components */}
        <HeroSection />
        <FocusCards />
        <PrinciplesSection />
      </Container>
    </PageLayout>
  );
};
export default OurPrinciples;
