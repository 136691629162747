// Key functional imports
import React from 'react';

import { Link, type PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import IntegrationIcon from 'stile-shared/assets/icons/icon_integration.svg';
import OneOnOneIcon from 'stile-shared/assets/icons/icon_one_on_one.svg';
// Icons
import QuestionIcon from 'stile-shared/assets/icons/icon_write_question.svg';
// Expert staff images
import Hailey from 'stile-shared/assets/images/responsive-support/Hailey.png';
import Hailey_mobile from 'stile-shared/assets/images/responsive-support/Hailey_Mobile.jpg';
import Jules from 'stile-shared/assets/images/responsive-support/avatar-jules.png';
import Lavinia from 'stile-shared/assets/images/responsive-support/avatar-lavinia.png';
import Marcus from 'stile-shared/assets/images/responsive-support/avatar-marcus.png';
import Max from 'stile-shared/assets/images/responsive-support/avatar-max.png';
import Penelope from 'stile-shared/assets/images/responsive-support/avatar-penelope.png';
// Group images
import Ear from 'stile-shared/assets/images/responsive-support/ear.svg';
import HeartHuman from 'stile-shared/assets/images/responsive-support/hearthuman.svg';
import Light from 'stile-shared/assets/images/responsive-support/light.svg';
import Notability from 'stile-shared/assets/images/responsive-support/notability.svg';
import Skill from 'stile-shared/assets/images/responsive-support/skill.svg';
import TeachersImage from 'stile-shared/assets/images/responsive-support/two-ladies.jpg';
import LeadersImage from 'stile-shared/assets/images/responsive-support/two-men.jpg';

import { ExploreCurriculum } from 'stile-shared/src/components/2024/common/ExploreCurriculum';
// Layout components
import TitleMenu from 'stile-shared/src/components/2024/common/Included/WITBTitleMenu/WITBTitleMenu';
import Top from 'stile-shared/src/components/2024/common/Included/WITBTopSection/WITBTopSection';
import { Section } from 'stile-shared/src/components/2024/layout/Section';

import { PageLayout } from 'templates/2024/PageLayout';

export { Head } from 'templates/2024/Head';

const ResponsiveSupportLayout = styled(PageLayout)`
  display: flex !important;
  flex-direction: column;

  .content {
    margin: 0 auto !important;
  }

  .grid-layout {
    display: flex !important;
    margin: 0 auto;
  }

  .main-content {
    display: flex !important;
    flex-direction: column;
    margin: 0 0;
    padding-bottom: 0;

    @media (max-width: 768px) {
      margin: 0 auto;
    }
  }

  .section,
  .footer {
    background-color: var(--stile-grey-8);
    width: 100%;
  }

  @media (max-width: 768px) {
  }
`;

const TitleMenuContainer = styled.div`
  display: flex !important;
  flex-direction: column !important;
  padding-bottom: 80px !important;

  @media (max-width: 768px) {
    width: calc(100vw - 30px);
    padding: 0 25px 40px !important;
    margin: 0 !important;
    > div > div {
      padding-top: calc(32px - 15px) !important;
    }
  }
`;

const ExtraTitleDescription = styled.p`
  width: 40%;
  margin: 18px auto 0;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 15px;
  }
`;

const NextContainer = styled(Section)`
  margin: 0 0 120px !important;
  padding: 0 !important;
  background: transparent;

  .content {
    padding: 0 !important;
    margin: 0 auto !important;
    .grid-layout {
      margin: 0 !important;
    }
  }

  @media (max-width: 768px) {
    width: calc(100vw - 30px);
    padding: 25px 15px 0 !important;
    margin: 0 0 0 !important;
  }
`;

const NextUp = styled.div`
  display: flex !important;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  gap: 18px;
  border-radius: calc(232 / 9 * 1px);
  padding: 60px 60px 60px 56px;
  background: var(--stile-grey-1);
  box-shadow: 0px 20.94px 46.72px 0px rgba(0, 0, 0, 0.05),
    0px 85.39px 85.39px 0px rgba(0, 0, 0, 0.04), 0px 191.72px 115.19px 0px rgba(0, 0, 0, 0.03),
    0px 339.94px 136.14px 0px rgba(0, 0, 0, 0.01), 0px 531.67px 149.03px 0px rgba(0, 0, 0, 0);

  @media (max-width: 768px) {
    width: calc(100vw - 30px);
    background: transparent;
    flex-direction: column;
    padding: 0 15px 0 !important;
    margin: 0 0 0 !important;
    box-shadow: none;
  }
`;

const MeetStile = styled.div`
  margin: 0;
  padding: 0;
  gap: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    gap: 28px;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const meet = {
  nameValue: 'Hailey',
  description:
    'Hailey is the ultimate science sidekick you didn’t know you needed! As your Teaching and Learning Specialist, she’s here to make sure you never have to tackle things solo.',
  list: {
    skill: 'Former Science and Special Education teacher',
    notability: 'Runs a STEM Summer Camp for girls.',
  },
};

const MeetContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex: 1;
`;

const MeetTidy = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
`;

const MeetList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 85%;
`;

const MeetName = styled.h2`
  width: 300px;
  margin-bottom: 0 !important;
  white-space: nowrap;
  text-align: left !important;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MeetDescription = styled.p`
  width: 95%;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  @media (max-width: 768px) {
    margin: 12px 0 20px 0;
  }
`;

const MeetListItem = styled.li<{ isMultiLine?: boolean }>`
  display: flex;
  align-items: ${({ isMultiLine }) => (isMultiLine ? 'flex-start' : 'center')};
  margin-bottom: 1rem;

  img {
    margin-right: 12px;
    width: 38px;
    height: 38px;
    flex-shrink: 0;
    align-self: ${({ isMultiLine }) => (isMultiLine ? 'flex-start' : 'center')};
  }

  span {
    display: inline-block;
    line-height: 1.4;
    max-width: calc(100% - 48px);
  }
`;

interface MeetProps {
  nameValue: string;
  description: string;
  list?: {
    skill: string;
    notability: string;
    light?: string;
    ear?: string;
    heart?: string;
  };
}

const iconMap: Record<string, string> = {
  skill: Skill,
  notability: Notability,
  light: Light,
  ear: Ear,
  heart: HeartHuman,
};

const MeetTheExpert: React.FC<MeetProps> = ({ nameValue, description, list }) => {
  if (!list || !list.skill || !list.notability) {
    console.error('MeetSection requires a list object with skill and notability fields.');
    return null;
  }

  const isMultiLine = (text: string) => {
    const lineCount = text.split('\n').length;
    return lineCount > 1;
  };

  const {
    skill,
    notability,
    light = 'Super smart',
    ear = 'Really good listener',
    heart = 'Real person, 0% AI',
  } = list;

  const finalList = { skill, notability, light, ear, heart };

  return (
    <MeetContainer>
      <MeetName>Meet {nameValue}</MeetName>
      <MeetDescription>{description}</MeetDescription>
      <MeetList>
        {Object.entries(finalList).map(([key, text]) => {
          const Icon = iconMap[key];
          const multiLine = isMultiLine(text);

          return (
            <MeetListItem key={key} isMultiLine={multiLine}>
              {Icon && <img src={Icon} alt="" />}
              <span>{text}</span>
            </MeetListItem>
          );
        })}
      </MeetList>
    </MeetContainer>
  );
};

const MeetEven = styled(MeetTheExpert)`
  flex: 1;
  width: auto;
  margin: auto 0;
  width: 500px;
  justify-content: space-around;

  @media (max-width: 768px) {
    width: 200px;
  }
`;

const MeetImageWrapper = styled.div`
  width: 100%;
  height: 623px;
  max-width: 502px;
  aspect-ratio: 5 / 4;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center; /* Center image within wrapper */
  margin-left: auto; /* Align wrapper to the right */
  border-radius: calc(43px / 8 * 7);

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    aspect-ratio: unset;
    /* width: auto; */
    height: auto;
  }
`;

const MeetImageMobile = styled.img`
  width: 100%;
  object-fit: contain;
  display: none;
  border-radius: 20px;
  @media (max-width: 768px) {
    display: block;
  }
`;

const MeetImageDesktop = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const PartnerWithSection = styled(Section)`
  display: flex;
  background-color: transparent !important;
  margin: 0 !important;
  .content {
    padding: 0 !important;
  }

  .grid-layout {
    margin-top: 0px !important;
  }

  @media (max-width: 768px) {
    .content {
      margin: 0 !important;
      margin-top: calc(60px - 16px) !important;
      padding: 0 25px 0 !important;
    }
  }
`;

const PartnerContainer = styled.div`
  display: flex;
  flex-direction: column !important;
  margin: 0;
  gap: 80px;
  align-content: center;
  @media (max-width: 768px) {
    width: calc(100vw - 30px);
    gap: 40px;
  }
`;

const PartnerTitle = styled.h2`
  width: 70%;
  text-align: center;
  margin: 0 auto !important;

  @media (max-width: 768px) {
    margin: auto 15px;
    width: 100%;
    text-align: left !important;
  }
`;

const PartnerTileContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 28px;

  @media (max-width: 768px) {
    width: calc(100vw - 50px);
    flex-direction: column;
    gap: 40px;
  }
`;

const PartnerData = [
  {
    imageSrc: TeachersImage,
    title: 'Teachers',
    detail:
      "Whether you’re stuck, want pedagogical advice, or want assistance customizing Stile for your students, Hailey's here to help. You can:",
    list: ['Call her cell', 'Text back-and-forth', 'Schedule a 20-minute coaching session'],
  },
  {
    imageSrc: LeadersImage,
    title: 'Leaders',
    detail:
      'Most curriculum implementations are a black box for leaders. To us, that doesn’t make sense. We provide regular reporting and strategic support so that leaders can be actively involved in Stile’s implementation.',
    list: [
      'Quarterly reporting',
      'Customized district-wide scope and sequence',
      'Customized units to integrate local phenomena',
    ],
  },
];

const PartnerTile = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: auto;
    margin-bottom: 32px;
    border-radius: 32px;
    box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.07), 0px 19px 19px 0px rgba(0, 0, 0, 0.06),
      0px 43px 26px 0px rgba(0, 0, 0, 0.04);

    @media (max-width: 768px) {
      margin-bottom: 20px;
      border-radius: 20px !important;
    }
  }

  h4 {
    margin: 0 0 20px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  p {
    margin: 0 0 20px;
    width: 85%;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  ul {
    list-style-type: disc;
    line-height: 24px;
    width: 85%;
    padding-left: 20px;
    margin: 5px;
  }

  li + li {
    margin-top: 12px;
  }
`;

const FullCastSection = styled(Section)`
  margin: 12px 0 150px !important;
  padding: 0 !important;
  background: transparent;

  @media (max-width: 768px) {
    margin: 0 0 60px !important;
    width: 100vw !important;

    .section {
      width: 100vw !important;
    }
    .content {
      padding-top: 60px !important;
      .grid-layout {
        margin: 0 !important;
      }
    }
  }
`;

const FullCastInner = styled.div`
  display: flex;
  flex-direction: column !important;
  align-items: center;
  gap: 40px;
  padding: 60px;
  background: var(--stile-grey-1);
  border-radius: 32px;

  @media (max-width: 768px) {
    padding: 60px 0;
    gap: 32px;
    border-radius: 20px !important;
  }
`;

const FullCastHeader = styled.div`
  width: 100%;
  text-align: center;
  gap: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    gap: 15px;
  }
`;

const FullCastTitleDesktop = styled.h2`
  font-size: 40px;
  margin: 0 !important;
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const FullCastTitleMobile = styled.h4`
  margin: 0 !important;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const FullCastDescription = styled.p`
  width: 44.75%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

interface FullCastImagesProps {
  height?: number;
}

const FullCastImages = styled.div<FullCastImagesProps>`
  display: flex;
  justify-content: center;
  --base-height: ${({ height = 126 }) => height}px;
  height: var(--base-height);
  position: relative;
  align-items: baseline;
  max-width: unset !important;

  @media (max-width: 768px) {
    --base-height: calc(126px * 0.7);
  }
`;

const ProfileImage = styled.img<{ distance?: number }>`
  position: absolute;
  --base-height: ${({ height = 126 }) => height}px;
  height: var(--base-height) !important;
  border-radius: 50%;
  border: 3.38px solid white;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: top;
  bottom: 0;
  max-width: unset !important;

  transform: ${({ distance = 0 }) =>
    `translateX(calc(${distance} * calc(var(--base-height) * 0.6)))`};

  @media (max-width: 768px) {
    --base-height: calc(126px * 0.7);
  }
`;

const SmallImage = styled(ProfileImage)`
  height: calc(var(--base-height) / 49 * 36); // small images are 1/7th less than the medium
  z-index: 5;
`;

const MediumImage = styled(ProfileImage)`
  height: calc(var(--base-height) / 7 * 6); // medium images are 1/7th less than the large
  z-index: 6;
`;

const LargeImage = styled(ProfileImage)`
  z-index: 7;
`;

const FullCastFeatures = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
`;

const ExpertIcons = [
  {
    imageSrc: QuestionIcon,
    question: 'Got a content question?',
    answer: 'Speak with the writer who crafted it.',
  },
  {
    imageSrc: IntegrationIcon,
    question: 'Struggling with an integration?',
    answer: 'Chat with the engineer who built it.',
  },
  {
    imageSrc: OneOnOneIcon,
    question: 'Want 1:1 help with a specific feature?',
    answer: 'Our support team has your back.',
  },
];

const ExpertTile = styled.div`
  align-items: center;
  margin: 0 auto;
  padding: 0 auto;
`;

const ExpertIcon = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 0 auto;
  justify-content: space-evenly;
`;

const ExpertQuestion = styled.p`
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 0;
  &:nth-child(2) {
    margin-top: 16px !important;
  }
  @media (max-width: 768px) {
    margin-top: 12px;
  }
`;

const ExpertAnswer = styled(ExpertQuestion)`
  font-weight: 600;
  margin-top: 0;
`;

const ContactUsButton = styled.button`
  background: var(--stile-green-1);
  padding: 12px 20px;
  border-radius: 6px;
  border: 2px solid var(--stile-green-1);
  font-size: 14px;
  font-weight: 600;
  color: var(--stile-grey-8);
  text-align: center;

  &:hover {
    --hover-green: #07fa64;
    background-color: var(--hover-green);
    border-color: var(--hover-green);
    cursor: pointer;
  }

  &:focus {
    outline: none;
    background: var(--stile-green-1);
    border: 2px solid var(--stile-green-3);
  }
`;

const QuoteSection = styled.div`
  background-color: var(--stile-green-2);
  margin: 0 0 0 !important;
  padding: 150px 0 140px 0;
  width: 100%;

  h2,
  p {
    color: var(--stile-grey-8);
    margin: 0 auto;
    text-align: center;
  }

  @media (max-width: 768px) {
    padding: 80px 29px 60px;
    gap: 10px;
    width: 100vw !important;
  }
`;

const Quote = styled.h2`
  width: 762px;
  padding-bottom: 32px;

  @media (max-width: 768px) {
    width: 100%;
    padding-bottom: 30px;
  }
`;

const Quoth = styled.p`
  margin-top: 32px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;

const From = styled(Quoth)`
  font-weight: 400;
`;

function ResponsiveSupportPage(props: PageProps) {
  const exploreCards = [
    {
      title: 'Professional Learning',
      description:
        'Master Stile at your own pace with evidence-based pedagogies and personalised support.',
      url: '/what-is-stile/stile-pl/',
    },
    {
      title: 'Guarantees and Principles',
      description: 'Some talk the talk, we walk the walk. Discover our non-negotiables.',
      url: '/who-we-are/our-principles',
    },
    {
      title: 'About Us',
      description: 'Want to discover more about who we are and what we do? Right this way.',
      url: '/who-we-are/our-team/',
    },
  ];

  return (
    <ResponsiveSupportLayout {...props}>
      <TitleMenuContainer className="content">
        <TitleMenu>
          <Top
            title="Responsive support"
            description="Successful curriculum adoptions look more like a partnership."
          />
          <ExtraTitleDescription>
            We hire smart, capable, driven science teachers and place them into roles dedicated to
            supporting Stile schools.
          </ExtraTitleDescription>
        </TitleMenu>
      </TitleMenuContainer>

      <NextContainer>
        <NextUp>
          <MeetStile>
            <MeetTidy>
              <MeetEven {...meet} />
            </MeetTidy>
            <MeetImageWrapper>
              <MeetImageDesktop src={Hailey} />
              <MeetImageMobile src={Hailey_mobile} />
            </MeetImageWrapper>
          </MeetStile>
        </NextUp>
      </NextContainer>

      <PartnerWithSection>
        <PartnerContainer>
          <PartnerTitle>Support specialists like {meet.nameValue} partner with</PartnerTitle>
          <PartnerTileContainer>
            {PartnerData.map((partner, index) => (
              <PartnerTile key={index}>
                <img src={partner.imageSrc} alt={partner.title} />
                <h4>{partner.title}</h4>
                <p>{partner.detail}</p>
                <ul>
                  {partner.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </PartnerTile>
            ))}
          </PartnerTileContainer>
        </PartnerContainer>
      </PartnerWithSection>

      <FullCastSection>
        <FullCastInner>
          <FullCastImages style={{ maxWidth: 'unset' }}>
            <SmallImage src={Lavinia} alt="Small profile" distance={-2} />
            <MediumImage src={Max} alt="Medium profile" distance={-1} />
            <LargeImage src={Jules} alt="Large profile" distance={0} />
            <MediumImage src={Penelope} alt="Medium profile" distance={1} />
            <SmallImage src={Marcus} alt="Small profile" distance={2} />
          </FullCastImages>

          <FullCastHeader>
            <FullCastTitleMobile>Plus a full cast of experts</FullCastTitleMobile>
            <FullCastTitleDesktop>Plus a full cast of experts</FullCastTitleDesktop>
            <FullCastDescription>
              Alex’s not the only one dedicated to supporting schools. Our team of scientists,
              pedagogy experts, and I.T. specialists are ready to help.
            </FullCastDescription>
          </FullCastHeader>

          <FullCastFeatures>
            {ExpertIcons.map((expert, index) => (
              <ExpertTile key={index}>
                <ExpertIcon>
                  <img alt="" src={expert.imageSrc} />
                </ExpertIcon>
                <ExpertQuestion>{expert.question}</ExpertQuestion>
                <ExpertAnswer>{expert.answer}</ExpertAnswer>
              </ExpertTile>
            ))}
          </FullCastFeatures>

          <Link to="/who-we-are/contact/">
            <ContactUsButton>Contact us</ContactUsButton>
          </Link>
        </FullCastInner>
      </FullCastSection>

      <QuoteSection>
        <Quote>“Stile has the best customer service I've ever had in any part of my life.”</Quote>
        <Quoth>Lex</Quoth>
        <From>Corvallis School District</From>
      </QuoteSection>

      <ExploreCurriculum
        title="Explore our core science curriculum"
        exploreCardsData={exploreCards}
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/responsive-support/book-with-grad-cap.png"
            alt="A book with a graduation cap"
          />
        }
      />
    </ResponsiveSupportLayout>
  );
}

export default ResponsiveSupportPage;
