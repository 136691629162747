import React, { useEffect, useRef, useState } from 'react';

import YouTubePlayer from 'react-player/youtube';
import styled from 'styled-components';

type VideoIconProps = {
  image: React.ReactNode;
  youtubeUrl: string;
  style?: 'primary' | 'secondary';
};

const StyledButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: relative;
  padding: 0;

  img {
    display: block;
    width: 100%;
    border-radius: 16px;
  }

  .primaryPlayButton,
  .secondaryPlayButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .primaryPlayButton circle {
    opacity: 0.5;
    fill: white;
  }

  .primaryPlayButton path {
    fill: #2b2b2b;
  }

  .secondaryPlayButton circle {
    opacity: 0.8;
    fill: white;
  }

  .secondaryPlayButton path {
    fill: #00e65a;
  }
`;

const StyledDialog = styled.dialog`
  background-color: transparent;
  border: none;
  padding: 0;
  width: 90vw;
  height: 90vh;

  ::backdrop {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
  }
`;

const VideoIcon: React.FC<VideoIconProps> = ({ image, youtubeUrl, style = 'primary' }) => {
  const refVideoDialogue = useRef<HTMLDialogElement | null>(null);
  const refVideoPlayer = useRef<YouTubePlayer | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (refVideoDialogue.current) {
      refVideoDialogue.current.addEventListener('close', () => {
        refVideoPlayer.current?.getInternalPlayer().pauseVideo();
      });
      refVideoDialogue.current.addEventListener('click', (e) => {
        if (e.target === refVideoDialogue.current) {
          refVideoDialogue.current?.close();
        }
      });
    }
  }, []);

  return (
    <>
      <StyledButton
        onClick={() => {
          refVideoDialogue?.current?.showModal();
          refVideoPlayer.current?.getInternalPlayer()?.playVideo();
        }}
      >
        {image}
        {isMobile ? (
          <svg
            className={`${style}PlayButton`}
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="22" />
            <path d="M32 22L17 30.6603L17 13.3397L32 22Z" />
          </svg>
        ) : (
          <svg
            className={`${style}PlayButton`}
            width="78"
            height="78"
            viewBox="0 0 78 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="39" cy="39" r="39" />
            <path d="M51 39L33 49.3923L33 28.6077L51 39Z" />
          </svg>
        )}
      </StyledButton>
      <StyledDialog ref={refVideoDialogue}>
        <YouTubePlayer
          ref={refVideoPlayer}
          tabIndex={0}
          url={youtubeUrl}
          width="100%"
          height="100%"
          playing={false}
          playsinline={false}
          controls={true}
          rel={0}
        />
      </StyledDialog>
    </>
  );
};

export default VideoIcon;
