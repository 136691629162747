import * as React from 'react';

import './Card.css';

type CardProps = React.HTMLAttributes<HTMLDivElement> & {
  cardTitle?: React.ReactElement | string;
  description?: React.ReactElement | string;
  callOuts?: string[];
  headerImage: React.ReactNode;
  backgroundColor?: Colors;
  button?: React.ReactElement;
};

/**
 * Displays Card
 */
export const Card = ({
  cardTitle,
  description,
  callOuts,
  headerImage,
  button,
  backgroundColor,
  ...props
}: React.PropsWithChildren<CardProps>) => {
  return (
    <div
      className="card"
      style={{
        backgroundColor: `${backgroundColor ? `var(${backgroundColor})` : `var(--white)`}`,
      }}
      {...props}
    >
      {cardTitle && <h4 className="header">{cardTitle}</h4>}
      <div className="card-image">{headerImage}</div>
      <div className="card-content">
        {callOuts && callOuts.length > 0 && (
          <ul>
            {callOuts.map((callOut, index) => (
              <li key={index}>{callOut}</li>
            ))}
          </ul>
        )}
        {description && <div className="description">{description}</div>}
      </div>
      {button && <div className="card-button">{button}</div>}
    </div>
  );
};
