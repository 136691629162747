import * as React from 'react';

import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import { Icon } from 'stile-shared/src/components/2024/common/Icon';
import { Underline } from 'stile-shared/src/components/2024/common/Underline';
import { TopSection } from 'stile-shared/src/components/2024/layout/RegionBasedTopSection';

import { PageLayout } from 'templates/2024/PageLayout';

import AlignedSection from 'components/blocks/states/AlignedSection';
import ExpertAssistance from 'components/blocks/states/ExpertAssistance';
import HearFromSection from 'components/blocks/states/HearFromSection';
import RealWorldSection from 'components/blocks/states/RealWorldSection';
import ScienceTestSection from 'components/blocks/states/ScienceTestSection';
import TestimonailsSection from 'components/blocks/states/TestimonailsSection';

export { Head } from 'templates/2024/Head';

function TwentyTwentyFourOregonPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <TopSection>
        <div className="content">
          <div className="--left">
            <h1>Oregon&apos;s favorite science curriculum</h1>
            <h6 className="h7">
              Last year, 22 Oregon districts adopted Stile for their middle schools — that&apos;s
              nearly a third of all adoptions made that year.
            </h6>
            <Underline underlineNumber={1} />
          </div>
          <div className="--right">
            <StaticImage
              src="../../../../stile-shared/assets/images/states/oregon/map.png"
              alt="cover image"
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
        <div className="">
          <div className="hearts">
            <div className="content">
              <span
                className="particle"
                style={{
                  top: '0',
                  left: '1%',
                  width: '10px',
                  height: '10px',
                  animationDelay: '0.8s',
                  zIndex: 9999,
                }}
              ></span>
              <span
                className="particle"
                style={{
                  top: '0',
                  left: '3%',
                  width: '10px',
                  height: '10px',
                  animationDelay: '2.5s',
                  zIndex: 9999,
                }}
              ></span>
              <span
                className="particle"
                style={{
                  top: '0',
                  left: '5%',
                  width: '10px',
                  height: '10px',
                  animationDelay: '3.5s',
                  zIndex: 9999,
                }}
              ></span>
            </div>
            <TestimonailsSection />
          </div>
        </div>
      </TopSection>

      <AlignedSection
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/states/oregon/orgeon-mountain.jpeg"
            alt="image of oregon"
          />
        }
        callOutTop="95%"
        callOutBottom="on the Oregon IMET"
        heading="Aligned and alive with the Oregon Science Standards"
        body={
          <p>
            There&apos;s &apos;alignment.&apos; And then there&apos;s actual alignment. Thanks to
            Stile&apos;s powerful and customizable platform, we deconstructed our NGSS Middle School
            curriculum and rebuilt it from the ground up. This means it includes brand-new lessons
            tailored specifically to meet the Oregon Science Standards.
            <br />
            <br />
            It&apos;s not surprising, then, that we <strong>
              scored 95% on the Oregon IMET
            </strong>{' '}
            (Instructional materials Evaluation Toolkit) by the Department of Education.
          </p>
        }
        buttons={[
          <Button
            key={0}
            href="/us/files/Stile IMET Oregon rubric.pdf"
            external={true}
            newTab={true}
            size="small"
            label={
              <div>
                <Icon iconId="document" iconSize="fontSize" style={{ marginRight: 10 }} />
                Download Stile’s IMET evaluation
              </div>
            }
          />,
          <Button
            key={1}
            href="/states/oregon-alignment"
            size="small"
            label="View Stile’s alignment to Oregon’s standards"
          />,
        ]}
      />

      <HearFromSection
        heading="Hear from Oregon science teacher, Mackenzie"
        subheading="Fully editable, if and when you want to. That’s Stile for you — putting teachers in the
            driver’s seat with full control of their lessons."
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/states/oregon/video.jpg"
            alt="thumbnail from a video"
          />
        }
        youtubeUrl="https://youtu.be/lIBdc--0GnE"
      />

      <RealWorldSection
        heading="Real-world phenomena from Oregon’s backyard"
        subheading="Many of Stile’s phenomena are drawn from wonders right around the world. After all, it’s important that students grow into global citizens. But sometimes, there’s nothing more interesting than investigating a phenomenon in the place you live. Stile’s curriculum for Oregon has a balanced blend of local and global phenomena."
        tabs={[
          'Oregon ghost towns',
          'Southern Oregon dam removal',
          'Oregon’s top marine biologist',
          'Oregon’s Lithium find',
        ]}
        headings={[
          'The rise and fall of historic Oregon towns',
          'Reviving ecosystems through dam removal',
          'Marine Conservation with Joleah Lamb',
          'The world’s largest Lithium deposit',
        ]}
        paragraphs={[
          "Students journey back to the 1800s, when Americans headed West in search of gold, settling in Oregon towns. These towns thrived but were soon abandoned, leaving behind ghost towns. In Stile's Active Earth unit, students uncover how geoscience led to uneven gold distribution. Pretty neat, huh?",
          'Diving into the fascinating story of removing four dams on the Klamath River, students witness how released sediments breathe new life into habitats and ecosystems. As part of our Energy Conservation unit, this hands-on exploration sharpens their critical thinking and showcases the real-world power of science-driven decisions.',
          "You can't dream it until you see it done. Students discover Assistant Professor Joleah Lamb, a marine biologist from Oregon, who’s uncovering the impacts of human activity on coral ecosystems. Joleah’s story, part of our Human Impacts on Ecosystems unit, shows students just how diverse and exciting a career in science can be.",
          'In 2023, geologists uncovered a massive lithium deposit in McDermitt Caldera, on the Oregon-Nevada border. With up to 40 million metric tons, this discovery was major. As part of our Breaking News unit, students explore why lithium matters, its global impact, and how finds like this shape our future. Talk about groundbreaking!',
        ]}
        images={[
          <StaticImage
            key={0}
            src="../../../../stile-shared/assets/images/states/oregon/slider-1.png"
            alt="feedback"
          />,
          <StaticImage
            key={1}
            src="../../../../stile-shared/assets/images/states/oregon/slider-2.png"
            alt="feedback"
          />,
          <StaticImage
            key={2}
            src="../../../../stile-shared/assets/images/states/oregon/slider-3.png"
            alt="feedback"
          />,
          <StaticImage
            key={3}
            src="../../../../stile-shared/assets/images/stile-pl/pl-slider-4.png"
            alt="feedback"
          />,
        ]}
      />

      <ScienceTestSection
        heading="Prepare your students for the Oregon Science Test"
        subHeading="The Oregon science test aims to measure students' science knowledge and critical thinking ability. Stile’s curriculum precisely scaffolds both of these, and provides students with plenty of opportunity to practice and excel on the Oregon Science Test."
        leftHeading="Oregon Science Test"
        leftContent={
          <>
            Venus and Earth have similar masses, but Venus travels faster and has an orbital period
            of less than one Earth year.
            <br />
            <br />
            Create a model to describe the difference in orbital speed. Draw one arrow for each
            planet to show the magnitude and direction of the force of gravity on each planet.
            Longer arrows represent forces with greater magnitude.
          </>
        }
        leftImage={
          <StaticImage
            src="../../../../stile-shared/assets/images/states/oregon/science-test-example.jpg"
            alt="example of a science test question"
          />
        }
        rightHeading="Stile Science Test"
        rightContent={
          <>
            Imagine you&apos;re out in space, looking down on the Sun, Earth, and Moon. <br />
            <br />
            Draw on the diagram to describe the role of gravity in their motions.
          </>
        }
        rightImage={
          <StaticImage
            src="../../../../stile-shared/assets/images/states/oregon/science-test-example-2.jpg"
            alt="example of a science test question"
          />
        }
      />

      <ExpertAssistance
        heading="Pilot Stile supported by our Oregon-based team"
        copy="Our team of supportive educators is based here in Portland to provide on-the-ground support, whenever you need it."
        href="/why-choose-stile/oregon-pilot/"
        expertImage={
          <StaticImage
            src="../../../../stile-shared/assets/images/states/oregon/expert.png"
            alt="Mark Picardo"
            quality={100}
            height={1250}
          />
        }
      />
    </PageLayout>
  );
}

export default TwentyTwentyFourOregonPage;
