import * as React from 'react';

import { Script } from 'gatsby';
import invariant from 'tiny-invariant';

import { stripIndent } from 'common-tags';

type ClarityScriptProps = { id?: string };

export function ClarityScript({ id }: ClarityScriptProps) {
  invariant(id, 'Clarity id was not provided');

  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  return (
    <Script
      id="clarity-script"
      dangerouslySetInnerHTML={{
        __html: stripIndent`
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${id}");
        `,
      }}
    />
  );
}
