import React, { ReactNode } from 'react';

import styled from 'styled-components';

const StyledBottomCTASection = styled.div`
  padding: 150px 0 0 0;
  background-color: var(--white);
  @media (max-width: 768px) {
    padding: calc(85px - 28px) 0 0 0;
    background-color: var(--stile-grey-1);
  }
  .content {
    border-radius: 32px;
    overflow: hidden;
    box-shadow: 0px 531.667px 149.028px 0px rgba(0, 0, 0, 0),
      0px 339.944px 136.139px 0px rgba(0, 0, 0, 0.01),
      0px 191.722px 115.194px 0px rgba(0, 0, 0, 0.03), 0px 85.389px 85.389px 0px rgba(0, 0, 0, 0.04),
      0px 20.944px 46.722px 0px rgba(0, 0, 0, 0.05);

    @media (max-width: 768px) {
      box-shadow: none;
      background-color: var(--white);
    }

    > div {
      padding: 60px;
      border-radius: 32px;
      border: 0.806px solid var(--stile-grey-1);
      background-color: var(--stile-grey-1);
      position: relative;
      overflow: hidden;

      display: flex;
      flex-direction: row;
      gap: 52px;

      @media (max-width: 768px) {
        flex-direction: column-reverse;
        padding: 0;
        border: 0px;
        border-radius: 0;
        gap: 20px;
      }

      .--left,
      .--right {
        flex-basis: 50%;
        @media (max-width: 768px) {
          flex-basis: 100%;
        }
      }

      .--left {
        align-content: center;
        .--headings {
          display: flex;
          flex-direction: column;
          gap: 22px;
          @media (max-width: 768px) {
          }
          .h9 {
            margin: 0;
            padding: 0;
          }
          h4 {
            margin: 0;
            padding: 0;
          }
        }
        .--copy {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin: 25px 0 0 0;
          @media (max-width: 768px) {
            margin: 12px 0 60px 0;
            gap: 10px;
          }
          p {
            width: 73%;
            margin: 0 0 15px 0;
            @media (max-width: 768px) {
              width: 100%;
              margin: 0;
            }
          }
          .button {
            div {
              margin-left: 6px;
            }
            &.mobile {
              display: none;

              @media (max-width: 768px) {
                display: flex;
              }
            }

            @media (max-width: 768px) {
              &.desktop {
                display: none;
              }
            }
          }
        }
      }

      .--right {
        .gatsby-image-wrapper {
          border-radius: 32px;
          @media (max-width: 768px) {
            width: 100%;
            border-radius: 20px;
          }
        }
      }
    }
  }
`;

type BottomCTAProps = {
  heading: string;
  copy: string | ReactNode;
  button: ReactNode;
  image: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export default function BottomCTA({ heading, copy, button, image }: BottomCTAProps) {
  return (
    <StyledBottomCTASection>
      <div className="content">
        <div>
          <div className="--left">
            <div className="--headings">
              <h4>{heading}</h4>
            </div>
            <div className="--copy">
              {copy}
              {button}
            </div>
          </div>
          <div className="--right">{image}</div>
        </div>
      </div>
    </StyledBottomCTASection>
  );
}
