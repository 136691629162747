import * as React from 'react';

import styled from 'styled-components';

import { CoverAnimation } from '../../common/CoverAnimation';
import { Underline } from '../../common/Underline';

import { Grid } from '../Grid';
import './TopSection.css';

type TopSectionProps = React.HTMLAttributes<HTMLDivElement> & {
  headingContent: React.ReactElement | string;
  subHeadingContent: React.ReactElement | string;
  coverImage?: React.ReactElement; // Can be StaticImage or CoverAnimation
  button?: React.ReactElement;
  children?: React.ReactNode | undefined;
  useAnimation?: boolean; // Add this prop to conditionally use animation
  underlineNumber?: number;
};

const TopSectionStyledDiv = styled.div`
  /* padding-bottom: 83px; */
  margin: '0 auto';
  background-color: var(--stile-grey-1);

  @media (max-width: 768px) {
    background-color: transparent;
    padding-bottom: 60px;
  }

  .content {
    .grid-layout {
      position: relative;
      padding-top: 100px;
      padding-bottom: 300px;

      @media (max-width: 768px) {
        padding-bottom: 0px;
      }

      @media (max-width: 768px) {
        padding-top: 23px;
      }
      > h1 {
        grid-column: span 7;
        padding: 0;
        margin: 0;
        z-index: 2;

        @media (max-width: 768px) {
          grid-column: span 12;
        }
      }
      > div {
        grid-column: 1 / span 4;

        @media (max-width: 768px) {
          grid-column: span 12;
        }

        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        z-index: 2;
        ul {
          list-style: disc;
          margin: 0;
          li {
            line-height: 22px;
            font-weight: 600;

            padding-bottom: 15px;
          }
        }
      }
      > .gatsby-image-wrapper {
        position: absolute;
        top: 100px;
        grid-column: 5 / span 8;
        z-index: 0;

        @media (max-width: 768px) {
          grid-row: 2;
          grid-column: span 12;
          margin-top: 0px;
          top: 0px;
          position: relative;
        }
      }
    }
  }
`;

/**
 * Displays content in a simple TopSection.
 */
export const TopSection = ({
  headingContent,
  subHeadingContent,
  coverImage,
  button,
  children,
  className,
  useAnimation = false, // Default to false if not provided
  underlineNumber = 1,
  ...props
}: React.PropsWithChildren<TopSectionProps>) => {
  return (
    <TopSectionStyledDiv className={`top-section ${className}`} {...props}>
      <div className="content">
        <Grid>
          <h1>{headingContent}</h1>
          <div>
            <span>{subHeadingContent}</span>
            <Underline underlineNumber={underlineNumber} />
            {button}
          </div>
          {/* Conditionally render CoverAnimation or StaticImage */}
          {useAnimation ? <CoverAnimation /> : coverImage}
        </Grid>
        {children}
      </div>
    </TopSectionStyledDiv>
  );
};
